@font-face {
    font-family: 'MapoGolden';
    font-weight: 900;
    src: url('./assets/fonts/MapoGoldenPier.ttf');
}

:root {
  --whitebeige: #f7f1eb;
  --beige: #e6c7a9;
  --goldbeige: #cf9f6f;
  --goldbrown: #9d7b5a;
  --lightgrey: #f8f8f8;
  --grey: #808080;
  --darkgrey: #34312e;
}
@font-face {
  font-family: NotoSans;
  src: url('//fonts.googleapis.com/earlyaccess/notosanskr.css');
}
/* reset */
* {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

iframe {
  border: none;
}

img {
  display: block;
  /* vertical-align: bottom; */
}

table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  padding: 0;
  margin: 0;
  font-size: 1rem;
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  font-family: inherit;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
/* reset end */
body {
  background-color: black;
}
.relative {
  position: relative;
}
.none {
  display: none;
}
.transparent {
  color: transparent;
}
.row {
  display: flex;
  flex-direction: row;
}
/* //////////////////////////////////////////////// */
/* /////////////////////////////////////////////// */

.App {
  font-family: NotoSans;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
}

.contents {
  width: 100%;
  max-width: 30rem;
  min-width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header {
  position: sticky;
  width: 100%;
  max-width: 30rem;
  min-width: 22.5rem;
  top: 0;
  left: 0;
  z-index: 10;
}
.logo {
  width: 100%;
  height: 3rem;
  padding: 0.8rem;
  background-color: var(--darkgrey);
}
.logo img {
  height: 1.2rem;
}
.nav-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: var(--goldbeige) 0.1rem solid;
  background-color: var(--darkgrey);
}

.nav-menu {
  width: 25%;
  height: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 800;
  color: var(--grey);
  letter-spacing: -0.022em;
}
.nav-menu-on {
  width: 25%;
  height: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 800;
  color: var(--goldbeige);
  letter-spacing: -0.022em;
}
.banner {
  position: relative;
  overflow: hidden;
  background-color: var(--darkgrey);
  display: flex;
  flex-direction: column;
}
.banner > img {
  width: 100%;
}
.banner,
.banner-shadow {
  width: 100%;
  height: 36.25rem;
}
.banner-shadow {
  position: absolute;
  top: 0;
  background: linear-gradient(
    var(--darkgrey),
    var(--darkgrey),
    rgba(52, 49, 46, 0.7),
    rgba(52, 49, 46, 0.5),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
  justify-content: space-between;
}
.subtitle {
  font-size: 1.25rem;
  color: white;
  margin: 3rem 0 2rem 0;
  text-align: center;
}
.banner-shadow > div > img {
  width: 15.125rem;
}

.event-texts {
  font-size: 1.2rem;
  font-family: "MapoGolden";
  color: white;
  margin-bottom: 15px;
}

.btns {
  width: 100%;
  padding: 3.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.find-btn {
  background-color: var(--goldbeige);
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  padding: 1.2rem 1.5rem;
  border-radius: 2rem;
  box-shadow: 0 0.3rem 0.5rem var(--darkgrey);
}
.take-a-look-btn {
  margin-top: 3rem;
  color: white;
  font-size: 1rem;
  text-align: center;
}
.icons {
  padding-top: 0.5rem;
  margin: 0 auto;
  height: 2rem;
  object-fit: cover;
  overflow: hidden;
}
/* /////////////////////////////////////////////// */
/* /////////////////////////////////////////////// */
.sections {
  width: 100%;
  background-color: var(--whitebeige);
}
.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 3.5rem 1rem;
}
#section01,
#section05 {
  background-color: var(--whitebeige);
}
#section02,
#section04,
#section07 {
  background-color: white;
}
#section03,
#section06,
#section08 {
  background-color: var(--lightgrey);
}
.section-title {
  width: 100%;
  text-align: left;
  font-size: 1.25rem;
  color: var(--goldbrown);
  margin-bottom: 1rem;
  font-weight: 800;
}
.section-desc {
  width: 100%;
  text-align: left;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

/* /////////////////////////////////////////////// */
/* /////////////////////////////////////////////// */

.float-btns {
  width: 100%;
  max-width: 30rem;
  min-width: 22.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  position: fixed;
  top: calc(100% - 7rem);
  z-index: 11;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
}
.float-btns > * {
  box-shadow: 0 0.3rem 0.5rem var(--grey);
}
.float-btns .find-btn {
  width: 100%;
}
.call-btn {
  flex-shrink: 0;
  margin-left: 0.5rem;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background-color: white;
  font-size: 3.5rem;
  line-height: 3.5rem;
  color: var(--goldbeige);
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-call {
  font-size: 2.5rem;
  color: var(--goldbeige);
}
/* /////////////////////////////////////////////// */
/* /////////////////////////////////////////////// */

.opin-card {
  width: 100%;
  min-width: 20rem;
  border-radius: 2rem;
  padding: 1.5rem 1.2rem;
  margin: 0.8rem 0;
  box-shadow: 0 0.1rem 0.5rem var(--grey);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  line-height: 1.5rem;
}
.profiles {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.profile-name {
  font-size: 1rem;
  color: black;
}
.profile-pic {
  width: 3.5rem;
  height: 3.5rem;
}
.profile {
  font-size: 0.8rem;
  line-height: 1.2rem;
  color: var(--grey);
}

/* /////////////////////////////////////////////// */
#section02 img,
#section04 img {
  margin: 4rem 0;
  width: 80%;
}
/* /////////////////////////////////////////////// */
.stepcard {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-left: var(--goldbrown) solid 0.1rem;
  padding: 1rem 0 2rem 0;
}
.dotted-line {
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  border-bottom: var(--goldbrown) dotted 0.1rem;
}
.circle {
  flex-shrink: 0;
  height: 1.8125rem;
  width: 1.8125rem;
  font-size: 0.875rem;
  line-height: 2rem;
  text-align: center;
  color: white;
  background-color: var(--goldbrown);
  border-radius: 50%;
}
.step-innercard {
  width: 100%;
  padding-left: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  color: var(--grey);
  gap: 0.8rem;
}
.step-innercard > img {
  object-fit: fill;
  width: 100%;
}
.step-title {
  font-size: 1.25rem;
  font-weight: 500;
  color: black;
}

/* /////////////////////////////////////////////// */
/* /////////////////////////////////////////////// */

.swiper-init {
  width: 100%;
  margin: 2rem auto;
}

.intro-card {
  flex-shrink: 0;
  width: 21rem;
  height: 32rem;
  padding: 1rem;
  margin: 4rem auto;
  border-radius: 1.5rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 0.875rem;
  color: var(--grey);
}
.intro-card > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}
.intro-card img {
  width: 100%;
  object-fit: contain;
}
.fivestar {
  color: var(--goldbeige);
}
.intro-text {
  font-size: 1.0635rem;
  color: black;
  font-weight: 500;
}
/* /////////////////////////////////////////////// */
#section06 > * {
  width: 100%;
}
/* /////////////////////////////////////////////// */
.info-card {
  margin: 1rem 0;
  padding: 1.25rem 1.125rem;
  width: 100%;
  border-radius: 2rem;
  box-shadow: 0 0rem 0.5rem #cecece;
  gap: 1rem;
  font-weight: 500;
  letter-spacing: -0.022em;
}
.logobox {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.info-title {
  font-size: 1.25rem;
  color: var(--goldbeige);
  font-weight: 700;
}
.carousel img {
  width: 100%;
  object-fit: contain;
  margin-bottom: 2rem;
}

.dots {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  margin: 0 0.3rem;
  background-color: var(--goldbrown);
}
.grey {
  background-color: var(--beige);
}
/* /////////////////////////////////////////////// */
/* /////////////////////////////////////////////// */

.faq-card {
  width: 20.5rem;
  border-bottom: 0.07rem solid #dbdbdb;
}
.question {
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.125rem;
  font-weight: 700;
}
.upsidedown {
  transform: rotate(180deg);
  transition: transform 0.5s;
  color: #a4a4a4;
}
.arrowdown {
  transform: rotate(0deg);
  transition: transform 0.5s;
  color: #a4a4a4;
}
.answer {
  padding: 1rem 0;
  font-size: 1rem;
}
/* /////////////////////////////////////////////// */
/* /////////////////////////////////////////////// */
#section08 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#section08 > p {
  font-size: 1rem;
  line-height: 2rem;
  color: #5f5f5f;
}
#section08 > p > span {
  font-weight: 700;
}
#section08 > div {
  padding: 1rem 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#section08 > div > p {
  font-size: 0.8rem;
  font-weight: 700;
}
.bottom-spacer {
  width: 100%;
  height: 3rem;
}
